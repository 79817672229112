// Fonts
$font-family-text: Lato, RyoGothicPlusN, sans-serif !default;
$font-family-heading: Poppins, RyoGothicPlusN, sans-serif !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$font-size-normal: 1rem !default;
$font-size-small: 0.9rem !default;
$font-size-large: 1.1rem !default;
$font-size-xlarge: 1.5rem !default;
$font-size-xxlarge: 2rem !default;
$font-size-xxxlarge: 3.5rem !default;
$font-size-xxxxlarge: 6rem !default;

$font-size-1: $font-size-normal * 2 !default;
$font-size-2: $font-size-normal * 1.8 !default;
$font-size-3: $font-size-normal * 1.6 !default;
$font-size-4: $font-size-normal * 1.4 !default;
$font-size-5: $font-size-normal * 1.2 !default;
$font-size-6: $font-size-normal * 1.1 !default;

// Spacing
$spacing: 1rem !default;
$spacing-xsmall: $spacing / 4 !default;
$spacing-small: $spacing / 2 !default;
$spacing-normal: $spacing * 1.5 !default;
$spacing-large: $spacing * 2 !default;
$spacing-xlarge: $spacing * 4 !default;
$spacing-xxlarge: $spacing * 8 !default;

// Breakpoints
$breakpoint-xsmall: 375px !default;
$breakpoint-small: 480px !default;
$breakpoint-medium: 768px !default;
$breakpoint-large: 1024px !default;
$breakpoint-short: 720px !default;

// Colours
$color-black: #000000 !default;
$color-white: #ffffff !default;

$color-grey-xdark: #1f2c3d !default;
$color-grey-dark: #4a4a4a !default;
$color-grey-mid: #5d5d5d !default;
$color-grey: #c8c8c8 !default;
$color-grey-light: #eaeaea !default;
$color-grey-xlight: #f5f4f5 !default;
$color-grey-xxlight: #f7f7f7 !default;

$color-sand: #e4e1d0 !default;
$color-blue: #0089c5 !default;
$color-blue-light: #def2fb !default;
$color-blue-dark: #424a59 !default;
$color-blue-xdark: #333b45 !default;

$color-green: #7ed321 !default;
$color-green-light: #e0f5c8 !default;
$color-red: #fd5c5c !default;
$color-red-light: #ffe5dd !default;
$color-yellow: #f7ee31 !default;

$color-orange-obento: #f78f47 !default;
$color-green-obento: #8dc63f !default;
$color-gray-terryandhunt: #949599 !default;
$color-light-grey-gk1: #bbc8bb !default;
$color-light-cream-gk2: #e1ceb0 !default;
$color-light-blue-b1: #bddeec !default;
$color-light-pink-b2: #ecc5b3 !default;
$color-light-pink-t1: #ecc5b3 !default;
$color-light-blue-t2: #d8eaf1 !default;
$color-light-pink-p1: #f8c4c5 !default;
$color-light-blue-p2: #b6e5f9 !default;
$color-sky-blue-f1: #e4f1f9 !default;
$color-rose-white-f2: #feeee5 !default;

$color-primary: $color-blue-dark !default;
$color-secondary: $color-blue !default;

$color-success: $color-green !default;
$color-success-light: $color-green-light !default;
$color-error: $color-red !default;
$color-error-light: $color-red-light !default;

$color-text: $color-grey-xdark !default;
$color-text-inverted: $color-white !default;
$color-heading: $color-black !default;
$color-link: $color-text !default;

$color-background: $color-sand !default;
