/**
 * Breakpoints
 */

@mixin large-up() {
  @media screen and (min-width: $breakpoint-large) {
    @content;
  }
}

@mixin medium-up() {
  @media screen and (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin extra-small-up() {
  @media screen and (min-width: $breakpoint-xsmall) {
    @content;
  }
}

@mixin small-up() {
  @media screen and (min-width: $breakpoint-small) {
    @content;
  }
}

@mixin large-down() {
  @media screen and (max-width: $breakpoint-large - 1) {
    @content;
  }
}

@mixin medium-down() {
  @media screen and (max-width: $breakpoint-medium - 1) {
    @content;
  }
}

@mixin extra-small-down() {
  @media screen and (max-width: $breakpoint-xsmall) {
    @content;
  }
}

@mixin small-down() {
  @media screen and (max-width: $breakpoint-small - 1) {
    @content;
  }
}

@mixin medium-only {
  @media screen and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large - 1) {
    @content;
  }
}

@mixin small-only() {
  @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium - 1) {
    @content;
  }
}

@mixin tall-up() {
  @media screen and (min-height: $short-breakpoint) and (min-width: $breakpoint-medium) {
    @content;
  }
}

@mixin print() {
  @media print {
    @content;
  }
}
