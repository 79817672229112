.error {
  background: $color-primary;
  bottom: 0;
  left: 0;
  overflow: auto;
  padding: $spacing-xlarge $spacing 0 $spacing;
  position: absolute;
  right: 0;
  top: 0;
}

.error__content {
  background: url('../media/error-space-man.svg') center bottom no-repeat;
  background-size: 60%;
  color: $color-white;
  height: 100%;
  min-height: 30rem;
  width: 100%;

  @include small-up {
    min-height: 40rem;
  }

  @include medium-up {
    min-height: 50rem;
  }

  @include large-up {
    background-size: 680px;
    min-height: 65rem;
  }
}

.error__title {
  font-family: $font-family-heading;
  font-size: $font-size-xxlarge;
  font-weight: $font-weight-bold;
  line-height: 1.4em;
  margin: 0 auto;
  max-width: 80%;
  text-shadow: 0 0 10px $color-primary;

  @include small-up {
    font-size: $font-size-xxxlarge;
  }

  @include large-up {
    font-size: $font-size-xxxxlarge;
    max-width: 52rem;
  }
}

.error__message {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  margin: $spacing-xlarge - $spacing 0 $spacing-xlarge 0;
  text-shadow: 0 0 10px $color-primary;

  @include small-up {
    font-size: $font-size-xlarge;
  }
}

.error__button,
.error__button:active,
.error__button:focus,
.error__button:visited {
  background: $color-white;
  border: 3px solid $color-sand;
  border-radius: 100px;
  box-shadow: 0 0 10px $color-primary;
  color: $color-primary;
  cursor: pointer;
  margin: 2px;
  font-family: $font-family-text;
  font-size: $font-size-normal;
  font-weight: $font-weight-bold;
  padding: $spacing-small $spacing-large;
  text-decoration: none;
  transition: 80ms all linear;
  white-space: nowrap;

  @include small-up {
    font-size: $font-size-large;
  }

  &:hover:not(:disabled) {
    border-color: $color-secondary;
    color: $color-secondary;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus {
    outline: 0 !important;
  }
}
