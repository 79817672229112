// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600');

@font-face {
  font-family: RyoGothicPlusN;
  font-weight: bold;
  src: url('./fonts/RyoGothicPlusN-Bold.otf') format('opentype');
}

@font-face {
  font-family: RyoGothicPlusN;
  src: url('./fonts/RyoGothicPlusN-Regular.otf') format('opentype');
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $color-grey-xxlight;
  color: $color-text;
  font-family: $font-family-text;
  font-weight: $font-weight-normal;
  line-height: 1.5em;
  margin: 0;
  padding-bottom: calc(6rem - #{$spacing-large});
  text-align: center;
  &.dark_blue_bg {
    background-color: $color-primary !important;
    @include large-down {
      background-color: $color-grey-xxlight !important;
    }
  }
  &.body--alt {
    @include large-down {
      background-color: $color-blue-xdark;
    }
  }

  &.body--no-footer {
    padding-bottom: 0 !important;
  }

  @include large-up {
    background-color: $color-background;
    padding-bottom: 6rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-heading;
  font-family: $font-family-heading;
  font-weight: $font-weight-bold;
  line-height: 1.5em;
  margin: $spacing 0;
}

h1 {
  font-size: $font-size-1;
}

h2 {
  font-size: $font-size-2;
}

h3 {
  font-size: $font-size-3;
}

h4 {
  font-size: $font-size-4;
}

h5 {
  font-size: $font-size-5;
}

h6 {
  font-size: $font-size-6;
}

button.aHref {
  background: none !important;
  color: $color-white;
  border: none;
  padding: 0 !important;
  font: inherit;
  outline: 0 !important;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid $color-grey;

  &:hover {
    color: $color-white;
    opacity: 0.6;
    text-decoration: none;
  }
}

a,
a:active,
a:focus,
a:visited {
  border-bottom: 1px solid $color-grey;
  color: $color-link;
  outline: 0 !important;
  text-decoration: none;

  &:hover {
    border-bottom-color: $color-blue;
    color: $color-blue;
  }
}

small {
  font-size: $font-size-small;
}

strong {
  font-weight: $font-weight-bold;
}

p {
  margin: $spacing 0;
}

hr {
  border: none;
  border-top: 1px solid $color-grey;
  margin: $spacing 0;
}
